<template>
	<div class="home">
		<billing-list-request></billing-list-request>
	</div>
</template>

<script>
import BillingList from '@/components/pages/report/billingList/billingListRequest.vue';

export default {
	components: {
		'billing-list-request': BillingList,
	}
};
</script>

<style>

</style>
